<template>
  <v-container fluid>
    <v-row class="justify-center d-flex mt-n5">
      <v-container fluid>
        <v-card class="px-5">
          <v-combobox
            label="Abkürzungen & Begriffe"
            :items="glossary"
            item-text="expression"
            return-object
            hide-details
            :search-input.sync="searchTerm"
            flat
            solo
            auto-select-first
            clearable
            :menu-props="{
              nudgeBottom: 15 + 'px',
              zIndex: 2,
              rounded: 'xl', // <-- Here ✅
            }"
          >
            <template v-slot:no-data>
              <div v-if="searchTerm && searchTerm.length > 2" class="px-4">
                Keine Begriffe gefunden
              </div>
              <div v-else class="px-4">Geben Sie mindestens 3 Zeichen ein</div>
            </template>
            <template v-slot:prepend>
              <v-icon size="18" class="text-typo mt-1">fas fa-search</v-icon>
            </template>
          </v-combobox>
        </v-card>
      </v-container>
      <v-container fluid v-if="searchTerm && searchTerm.length > 2">
        <p class="text-white text-h4">SUCHERGEBNISSE</p>
        <v-card rounded="xl">
          <v-hover
            v-slot="{ hover }"
            open-delay="100"
            v-for="expression in filteredGlossary"
            :key="expression.index"
          >
            <v-card
              class="pa-4 my-2"
              :elevation="hover ? 12 : 0"
              @click="
                $router
                  .push('/begriff/' + expression.expression)
                  .catch(() => {})
              "
            >
              <v-row class="ma-0 pa-0">
                <div>
                  <v-avatar
                    rounded
                    width="50"
                    height="50"
                    style="
                      background-color: lightgray;
                      border-radius: 100px !important;
                    "
                  >
                  <v-img
                          max-width="25"
                          max-height="25"
                          :src="require(`@/assets/icons/${expression.category.icon}.png`)"
                        ></v-img>
                  </v-avatar>
                </div>
                <div class="ml-3">
                  <p class="text-h5 ma-0 pa-0">{{ expression.expression }}</p>
                  <p class="ma-0 pa-0 text-body">
                    {{ expression.views.length }} Ansicht{{
                      expression.views.length > 1 ||
                      expression.views.length == 0
                        ? "en"
                        : ""
                    }}
                    • {{ expression.ratings.length }} Bewertung{{
                      expression.ratings.length > 1 ||
                      expression.ratings.length == 0
                        ? "en"
                        : ""
                    }}
                  </p>
                </div>
              </v-row>
            </v-card>
          </v-hover>
        </v-card>
      </v-container>
      <v-container fluid>
        <p class="text-white text-h4" style="font-family: 'Cabin' !important;">ALLE KATEGORIEN</p>
        <v-row>
          <v-col
            cols="12"
            md="6"
            v-for="category in categories"
            :key="category.index"
          >
            <v-hover v-slot="{ hover }" open-delay="100">
              <v-card
                class="pa-4"
                rounded="xl"
                :elevation="hover ? 12 : 2"
                @click="
                  $router.push('/kategorien/' + category.link).catch(() => {})
                "
              >
                <v-row class="ma-0 pa-0">
                  <div>
                    <v-avatar
                      rounded
                      width="50"
                      height="50"
                      class="pa-4"
                      style="
                        background-color: lightgray;
                        border-radius: 100px !important;
                      "
                    >
                      <div class="pt-1">
                        <v-img
                          max-width="25"
                          max-height="25"
                          :src="require(`@/assets/icons/${category.icon}.png`)"
                        ></v-img>
                      </div>
                    </v-avatar>
                  </div>
                  <div class="ml-3">
                    <p class="text-h5 ma-0 pa-0" style="font-family: 'Cabin' !important;">{{ category.title }}</p>
                    <p class="ma-0 pa-0 text-body">
                      {{ category.glossaries.length }} Begriff{{
                        category.glossaries.length > 1 ||
                        category.glossaries.length == 0
                          ? "e"
                          : ""
                      }}
                    </p>
                  </div>
                </v-row>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "home",
  mounted() {
    this.$store.dispatch("getGlossary");
    this.$store.dispatch("getCategory");
  
  },
  computed: {
    glossary() {
      if (this.$store.getters.glossary.data) {
        if (this.searchTerm && this.searchTerm.length > 2) {
          return this.$store.getters.glossary.data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    filteredGlossary() {
      return this.glossary.filter((expression) =>
        expression.expression
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase())
      );
    },
    categories() {
      return this.$store.getters.categories.data;
    },
    userInformations(){
      return this.$store.getters.userInformations;
    }
  },
  watch: {
    // searchTerm() {
    //   if (this.searchTerm) {
    //     this.$store.dispatch("selectedGlossary", this.searchTerm);
    //     this.$router
    //       .push("/begriff/" + this.searchTerm.expression)
    //       .catch(() => {});
    //   }
    // },
  },
  data() {
    return {
      searchTerm: "",
      search: "",
      // categories: [
      //   {
      //     title: "Marketing",
      //     amount: 123,
      //     icon: "fas fa-building",
      //     link: "marketing",
      //   },
      //   {
      //     title: "Gründerszene",
      //     amount: 123,
      //     icon: "fas fa-rocket",
      //     link: "Gründerszene",
      //   },
      //   {
      //     title: "Finanzierung",
      //     amount: 123,
      //     icon: "fas fa-dollar-sign",
      //     link: "finanzierung",
      //   },
      //   {
      //     title: "Rechtliches",
      //     amount: 123,
      //     icon: "fas fa-building",
      //     link: "rechtliches",
      //   },
      //   {
      //     title: "Große Corporates",
      //     amount: 123,
      //     icon: "fas fa-building",
      //     link: "Große Corporates",
      //   },
      //   {
      //     title: "Digitalisierung",
      //     amount: 123,
      //     icon: "fas fa-building",
      //     link: "digitalisierung",
      //   },
      // ],
      plans: [
        {
          title: "Starter",
          price: "119",
          buttonColor: "default",
          buttonText: "Join",
          features: [
            {
              title: "2 team members",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "20GB Cloud storage",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "Integration help",
              icon: "fas fa-minus",
              color: "secondary",
            },
            {
              title: "Sketch Files",
              icon: "fas fa-minus",
              color: "secondary",
            },
            {
              title: "API Access",
              icon: "fas fa-minus",
              color: "secondary",
            },
            {
              title: "Complete documentation",
              icon: "fas fa-minus",
              color: "secondary",
            },
          ],
        },
        {
          title: "Premium",
          price: "159",
          buttonColor: "primary",
          buttonText: "Try Premium",
          features: [
            {
              title: "10 team members",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "40GB Cloud storage",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "Integration help",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "Sketch Files",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "API Access",
              icon: "fas fa-minus",
              color: "secondary",
            },
            {
              title: "Complete documentation",
              icon: "fas fa-minus",
              color: "secondary",
            },
          ],
        },
        {
          title: "Enterprise",
          price: "399",
          buttonColor: "default",
          buttonText: "Join",
          features: [
            {
              title: "Unlimited team members",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "100GB Cloud storage",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "Integration help",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "Sketch Files",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "API Access",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "Complete documentation",
              icon: "fas fa-check",
              color: "success",
            },
          ],
        },
      ],
      brands: [
        {
          name: "coinbase",
          image: require("@/assets/img/logos/gray-logos/logo-coinbase.svg"),
        },
        {
          name: "nasa",
          image: require("@/assets/img/logos/gray-logos/logo-nasa.svg"),
        },
        {
          name: "netflix",
          image: require("@/assets/img/logos/gray-logos/logo-netflix.svg"),
        },
        {
          name: "pinterest",
          image: require("@/assets/img/logos/gray-logos/logo-pinterest.svg"),
        },
        {
          name: "spotify",
          image: require("@/assets/img/logos/gray-logos/logo-spotify.svg"),
        },
        {
          name: "vodafone",
          image: require("@/assets/img/logos/gray-logos/logo-vodafone.svg"),
        },
      ],
      panels: [
        {
          title: "How do I order?",
          description:
            "We’re not always in the position that we want to be at. We’re constantly growing. We’re constantly making mistakes. We’re constantly trying to express ourselves and actualize our dreams. If you have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s passed.",
        },
        {
          title: "How can i make the payment?",
          description:
            "It really matters and then like it really doesn’t matter. What matters is the people who are sparked by it. And the people who are like offended by it, it doesn’t matter. Because it's about motivating the doers. Because I’m here to follow my dreams and inspire other people to follow their dreams, too. We’re not always in the position that we want to be at. We’re constantly growing. We’re constantly making mistakes. We’re constantly trying to express ourselves and actualize our dreams. If you have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s passed.",
        },
        {
          title: "How much time does it take to receive the order?",
          description:
            "The time is now for it to be okay to be great. People in this world shun people for being great. For being a bright color. For standing out. But the time is now to be okay to be the greatest you. Would you believe in what you believe in, if you were the only one who believed it? If everything I did failed - which it doesn't, it actually succeeds - just the fact that I'm willing to fail is an inspiration. People are so scared to lose that they don't even try. Like, one thing people can't say is that I'm not trying, and I'm not trying my hardest, and I'm not trying to do the best way I know how.",
        },
        {
          title: "Can I resell the products?",
          description:
            "I always felt like I could do anything. That’s the main thing people are controlled by! Thoughts- their perception of themselves! They're slowed down by their perception of themselves. If you're taught you can’t do anything, you won’t do anything. I was taught I could do everything. If everything I did failed - which it doesn't, it actually succeeds - just the fact that I'm willing to fail is an inspiration. People are so scared to lose that they don't even try. Like, one thing people can't say is that I'm not trying, and I'm not trying my hardest, and I'm not trying to do the best way I know how.",
        },
        {
          title: "Where do I find the shipping details?",
          description:
            "There’s nothing I really wanted to do in life that I wasn’t able to get good at. That’s my skill. I’m not really specifically talented at anything except for the ability to learn. That’s what I do. That’s what I’m here for. Don’t be afraid to be wrong because you can’t learn anything from a compliment. I always felt like I could do anything. That’s the main thing people are controlled by! Thoughts- their perception of themselves! They're slowed down by their perception of themselves. If you're taught you can’t do anything, you won’t do anything. I was taught I could do everything.",
        },
      ],
    };
  },
};
</script>
