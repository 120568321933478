<template>
  <v-container fluid>
    <p class="text-white text-center text-h4">Impressum</p>
  </v-container>
</template>
<script>
export default {
  name: "Impressum",
  data() {
    return {};
  },
};
</script>
