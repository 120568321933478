<template>
  <v-container fluid>
    <v-row class="justify-center d-flex mt-n5">
      <v-container fluid>
        <p class="text-white text-h4 text-uppercase" style="font-family: 'Cabin' !important;">{{ $route.params.id }}</p>
        <v-card class="px-5">
          <v-combobox
            :search-input.sync="searchTerm"
            label="Abkürzungen & Begriffe"
            :items="glossary"
            item-text="expression"
            return-object
            hide-details
            auto-select-first
            flat
            solo
            :menu-props="{
              nudgeBottom: 15 + 'px',
              zIndex: 2,
              rounded: 'xl', // <-- Here ✅
            }"
          >
            <template v-slot:no-data>
              <div class="px-4">Keine Begriffe gefunden</div>
            </template>
            <template v-slot:prepend>
              <v-icon size="18" class="text-typo mt-1">fas fa-search</v-icon>
            </template>
          </v-combobox>
        </v-card>
      </v-container>
      <v-container fluid>
        <p class="text-white text-h4" style="font-family: 'Cabin' !important;">SUCHERGEBNISSE</p>
        <v-card rounded="xl">
          <v-hover v-slot="{ hover }" open-delay="100" v-for="expression in filteredGlossary" :key="expression.index">
              <v-card
                class="pa-4 my-2"
                
                :elevation="hover ? 12 : 0"
                @click="
                  $router.push('/begriff/' + expression.expression).catch(() => {})
                "
              >
                <v-row class="ma-0 pa-0">
                  <div>
                    <v-avatar
                      rounded
                      width="50"
                      height="50"
                      class="pa-4"
                      style="
                        background-color: lightgray;
                        border-radius: 100px !important;
                      "
                    >
                      <div class="pt-1">
                        <v-img
                          max-width="25"
                          max-height="25"
                          :src="require(`@/assets/icons/${expression.category.icon}.png`)"                  
                        ></v-img>
                      </div>
                    </v-avatar>
                    
                  </div>
                  <div class="ml-3">
                    <p class="text-h5 ma-0 pa-0" style="font-family: 'Cabin' !important;">{{ expression.expression }}</p>
                    <p class="ma-0 pa-0 text-body">
                      {{ expression.views.length }} Ansicht{{
                        expression.views.length > 1 ||
                        expression.views.length == 0
                          ? "en"
                          : ""
                      }} • {{ expression.ratings.length }} Bewertung{{
                        expression.ratings.length > 1 ||
                        expression.ratings.length == 0
                          ? "en"
                          : ""
                      }}
                    </p>
                  </div>
                </v-row>
              </v-card>
            </v-hover>
        </v-card>
      </v-container>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Kategorie",
  mounted() {
    this.$store.dispatch("getGlossary");
    this.$store.dispatch("getCategory");
  },
  watch: {
    // searchTerm() {
    //   if (this.searchTerm) {
    //     this.$store.dispatch("selectedGlossary", this.searchTerm);
    //     this.$router
    //       .push("/begriff/" + this.searchTerm.expression)
    //       .catch(() => {});
    //   }
    // },
  },
  computed: {
    glossary() {
      if (this.$store.getters.glossary.data) {
        return this.$store.getters.glossary.data.filter(
          (element) => element.category.link == this.$route.params.id
        );
      } else {
        return [];
      }
    },
    filteredGlossary(){
      if(this.searchTerm){
        return this.glossary.filter(expression => expression.expression.toLowerCase().includes(this.searchTerm.toLowerCase()))
      } else {
        return this.glossary
      }
      
    },
    categories() {
      return this.$store.getters.categories.data;
    },
  },
  data() {
    return {
      searchTerm: null,
    };
  },
};
</script>
