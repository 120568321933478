<template>
  <v-container fluid>
    <p class="text-white text-center text-h4">Kontakt</p>
  </v-container>
</template>
<script>
export default {
  name: "Kontakt",
  data() {
    return {};
  },
};
</script>
