var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"justify-center d-flex mt-n5"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"px-5"},[_c('v-combobox',{attrs:{"label":"Abkürzungen & Begriffe","items":_vm.glossary,"item-text":"expression","return-object":"","hide-details":"","search-input":_vm.searchTerm,"flat":"","solo":"","auto-select-first":"","clearable":"","menu-props":{
            nudgeBottom: 15 + 'px',
            zIndex: 2,
            rounded: 'xl', // <-- Here ✅
          }},on:{"update:searchInput":function($event){_vm.searchTerm=$event},"update:search-input":function($event){_vm.searchTerm=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.searchTerm && _vm.searchTerm.length > 2)?_c('div',{staticClass:"px-4"},[_vm._v(" Keine Begriffe gefunden ")]):_c('div',{staticClass:"px-4"},[_vm._v("Geben Sie mindestens 3 Zeichen ein")])]},proxy:true},{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"text-typo mt-1",attrs:{"size":"18"}},[_vm._v("fas fa-search")])]},proxy:true}])})],1)],1),(_vm.searchTerm && _vm.searchTerm.length > 2)?_c('v-container',{attrs:{"fluid":""}},[_c('p',{staticClass:"text-white text-h4"},[_vm._v("SUCHERGEBNISSE")]),_c('v-card',{attrs:{"rounded":"xl"}},_vm._l((_vm.filteredGlossary),function(expression){return _c('v-hover',{key:expression.index,attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-4 my-2",attrs:{"elevation":hover ? 12 : 0},on:{"click":function($event){_vm.$router
                .push('/begriff/' + expression.expression)
                .catch(function () {})}}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('div',[_c('v-avatar',{staticStyle:{"background-color":"lightgray","border-radius":"100px !important"},attrs:{"rounded":"","width":"50","height":"50"}},[_c('v-img',{attrs:{"max-width":"25","max-height":"25","src":require(("@/assets/icons/" + (expression.category.icon) + ".png"))}})],1)],1),_c('div',{staticClass:"ml-3"},[_c('p',{staticClass:"text-h5 ma-0 pa-0"},[_vm._v(_vm._s(expression.expression))]),_c('p',{staticClass:"ma-0 pa-0 text-body"},[_vm._v(" "+_vm._s(expression.views.length)+" Ansicht"+_vm._s(expression.views.length > 1 || expression.views.length == 0 ? "en" : "")+" • "+_vm._s(expression.ratings.length)+" Bewertung"+_vm._s(expression.ratings.length > 1 || expression.ratings.length == 0 ? "en" : "")+" ")])])])],1)]}}],null,true)})}),1)],1):_vm._e(),_c('v-container',{attrs:{"fluid":""}},[_c('p',{staticClass:"text-white text-h4",staticStyle:{"font-family":"'Cabin' !important"}},[_vm._v("ALLE KATEGORIEN")]),_c('v-row',_vm._l((_vm.categories),function(category){return _c('v-col',{key:category.index,attrs:{"cols":"12","md":"6"}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-4",attrs:{"rounded":"xl","elevation":hover ? 12 : 2},on:{"click":function($event){_vm.$router.push('/kategorien/' + category.link).catch(function () {})}}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('div',[_c('v-avatar',{staticClass:"pa-4",staticStyle:{"background-color":"lightgray","border-radius":"100px !important"},attrs:{"rounded":"","width":"50","height":"50"}},[_c('div',{staticClass:"pt-1"},[_c('v-img',{attrs:{"max-width":"25","max-height":"25","src":require(("@/assets/icons/" + (category.icon) + ".png"))}})],1)])],1),_c('div',{staticClass:"ml-3"},[_c('p',{staticClass:"text-h5 ma-0 pa-0",staticStyle:{"font-family":"'Cabin' !important"}},[_vm._v(_vm._s(category.title))]),_c('p',{staticClass:"ma-0 pa-0 text-body"},[_vm._v(" "+_vm._s(category.glossaries.length)+" Begriff"+_vm._s(category.glossaries.length > 1 || category.glossaries.length == 0 ? "e" : "")+" ")])])])],1)]}}],null,true)})],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }