<template>
  <v-container fluid>
    <v-card class="pa-5 my-3" style="background-color: rgba(200, 211, 209, 1)">
      <p class="text-h4" style="font-family: 'Cabin' !important;">DEFINITION</p>
      <span class="text-body" v-html="glossary.explanation"></span>
    </v-card>
    <v-card
      v-if="glossary.explanationDetail"
      class="pa-5 my-3"
      style="background-color: rgba(200, 211, 209, 0.4)"
    >
      <p class="text-h4" style="font-family: 'Cabin' !important;">ERLÄUTERUNG</p>
      <span class="text-body" v-html="glossary.explanationDetail"></span>
    </v-card>
    <v-card
      v-if="glossary.example"
      class="pa-5 my-3"
      style="background-color: rgba(200, 211, 209, 0.2)"
    >
      <p class="text-h4" style="font-family: 'Cabin' !important;">BEISPIEL</p>
      <span class="text-body" v-html="glossary.example"></span>
    </v-card>

    <v-container
      fluid
      class="ma-0 pa-0 mt-10"
      v-if="filteredGlossary && filteredGlossary.length > 0"
    >
      <p class="text-h4 white--text" style="font-family: 'Cabin' !important;">Kennst Du diese Begriffe schon?</p>
      <v-card rounded="xl">
        <v-hover
          v-slot="{ hover }"
          open-delay="100"
          v-for="expression in filteredGlossary"
          :key="expression.index"
        >
          <v-card
            class="pa-4 my-2"
            style="background-color: rgba(200, 211, 209, 0.2)"
            :elevation="hover ? 12 : 0"
            @click="
              $router.push('/begriff/' + expression.expression).catch(() => {}); scrollTo(0, 0);
            "
          >
            <v-row class="ma-0 pa-0">
              <div>
                <v-avatar
                  rounded
                  width="50"
                  height="50"
                  class="pa-4"
                  style="
                    background-color: lightgray;
                    border-radius: 100px !important;
                  "
                >
                  <div class="pt-1">
                    <v-img
                      max-width="25"
                      max-height="25"
                      :src="
                        require(`@/assets/icons/${expression.category.icon}.png`)
                      "
                    ></v-img>
                  </div>
                </v-avatar>
              </div>
              <div class="ml-3">
                <p class="text-h5 ma-0 pa-0" style="font-family: 'Cabin' !important;">{{ expression.expression }}</p>
                <p class="ma-0 pa-0 text-body">
                  {{ expression.views.length }} Ansicht{{
                    expression.views.length > 1 || expression.views.length == 0
                      ? "en"
                      : ""
                  }}
                  • {{ expression.ratings.length }} Bewertung{{
                    expression.ratings.length > 1 ||
                    expression.ratings.length == 0
                      ? "en"
                      : ""
                  }}
                </p>
              </div>
              <v-btn
                :ripple="false"
                elevation="0"
                color="#fff"
                class="ml-auto my-auto hidden-sm-and-down font-weight-bolder py-5"
                :class="'btn-primary bg-warning'"
                href="javascript:;"
                @click="
                  $router
                    .push('/begriff/' + expression.expression)
                    .catch(() => {}); scrollTo(0, 0);
                "
              >
                Definition lesen
              </v-btn>
            </v-row>
          </v-card>
        </v-hover>
      </v-card>
    </v-container>
  </v-container>
</template>
<script>
export default {
  name: "Begriff",
  mounted() {
    this.$store.dispatch("getGlossary");
    this.$store.dispatch("getCategory");
  },
  watch: {
    glossary() {
      this.$store.commit("selectedGlossary", this.glossary);

      if (
        (!this.checkGlossary && this.glossary) ||
        (this.glossary && this.checkGlossary.id != this.glossary.id)
      ) {
        this.$store.dispatch("postGlossaryView", this.glossary);
      }
      this.checkGlossary = this.glossary;
    },
  },
  computed: {
    glossary() {
      if (this.$store.getters.glossary.data) {
        return this.$store.getters.glossary.data.find(
          (element) => element.expression == this.$route.params.id
        );
      } else {
        return [];
      }
    },
    categories() {
      return this.$store.getters.categories.data;
    },
    currentIndex() {
      try {
        let index = this.$store.getters.glossary.data
          .filter(
            (expression) => expression.category.id == this.glossary.category.id
          )
          .map((e) => e.id)
          .indexOf(this.glossary.id);
        return index;
      } catch (error) {
        return 0;
      }
    },
    filteredGlossary() {
      try {
        return this.$store.getters.glossary.data
          .filter(
            (expression) =>
              expression.category.id == this.glossary.category.id &&
              expression.id != this.glossary.id
          )
          .slice(this.currentIndex, this.currentIndex + 4);
      } catch (error) {
        return [];
      }
    },
  },
  data() {
    return {
      checkGlossary: null,
    };
  },
  methods: {
    scrollTo(x, y){
      window.scrollTo(x, y)
    }
  }
};
</script>
