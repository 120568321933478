<template>
  <v-container fluid class="py-6">
    
    <v-row>
     
      <v-col >
        <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
          <v-row>
            <v-col cols="auto">
              <v-avatar width="74" height="74" class="shadow border-radius-lg" v-if="userInformations.username || userInformations.avatar">
                <img
                  v-if="userInformations.avatar"
                  src="@/assets/img/jonas.png"
                  alt="Avatar"
                  class="border-radius-lg"
                />
                <p class="text-h1 my-auto text-primary">{{ userInformations.username[0] }}</p>
              </v-avatar>
            </v-col>
            <v-col cols="auto" class="my-auto">
              <div class="h-100">
                <h5 class="mb-1 text-h5 text-typo font-weight-bold">
                  {{ userInformations.firstname }} {{ userInformations.lastname }}
                </h5>
                <p class="mb-0 font-weight-bold text-body text-sm">
                  {{ userInformations.username }}
                </p>
              </div>
            </v-col>
            <!-- <v-col
              lg="4"
              md="6"
              class="my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3"
            >
              <div class="d-flex align-center">
                <p
                  class="mb-0 text-body text-xs ms-auto"
                  v-if="switche == true"
                >
                Auf unsichtbar schalten
                </p>
                <p
                  class="mb-0 text-body text-xs ms-auto"
                  v-if="switche == false"
                >
                Auf sichtbar umschalten
                </p>
                <v-switch
                  :ripple="false"
                  class="mt-0 pt-0 ms-3 switch"
                  v-model="switche"
                  hide-details
                  color="#3a416ff2"
                  inset
                ></v-switch>
              </div>
            </v-col> -->
          </v-row>
        </v-card>
        <basic-info></basic-info>
        <change-password></change-password>
        <delete-account></delete-account>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BasicInfo from "./Widgets/BasicInfo.vue";
import ChangePassword from "./Widgets/ChangePassword.vue";
import DeleteAccount from "./Widgets/DeleteAccount.vue";

export default {
  name: "Settings",
  components: {
    BasicInfo,
    ChangePassword,
    DeleteAccount,
  },
  mounted(){
    if(!this.userInformations){
      this.$router.push('/').catch(() => {})
    }
  },
  computed: {
    userInformations(){
      return this.$store.getters.userInformations;
    }
  },
  data() {
    return {
      switche: true,
    };
  },
};
</script>
